import { useState } from 'react';

import { useLockAction } from './use-lock-action';

export const useHandleLock = (initialLocked: boolean, id: number) => {
  const [isLocked, setIsLocked] = useState(initialLocked);
  const { mutate: toggleLock, isLoading } = useLockAction(isLocked);

  const onLockError = () => {
    setIsLocked(initialLocked);
  };

  const handleToggle = () => {
    setIsLocked(prev => !prev);
    toggleLock({
      attributeId: id,
      onError: onLockError,
    });
  };

  return { isLocked, handleToggle, isLoading };
};
