import { twMerge } from 'tailwind-merge';

interface ContentCellProps {
  content: React.ReactNode;
  classOverride?: string;
}

const ContentCell: React.FC<ContentCellProps> = ({
  content,
  classOverride = '',
}) => {
  return (
    <div
      className={twMerge(
        'text-sm text-ellipsis overflow-hidden whitespace-normal text-justify',
        classOverride,
      )}
      style={{ wordBreak: 'break-word' }}
    >
      {content}
    </div>
  );
};

export default ContentCell;
