import { useMutation } from 'react-query';

import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';

import { AssetsHierarchy } from '../types';

export const useUpdateAssetsHierarchy = () => {
  const client = createHttpClient();

  const mutation = useMutation(
    ({
      clientId,
      payload,
      onSuccess,
    }: {
      clientId: number;
      payload: AssetsHierarchy;
      onSuccess: () => void;
    }) =>
      client({
        url: END_POINTS.HIERARCHY.ASSETS_HIERARCHY(clientId),
        method: HttpMethod.PATCH,
        data: payload,
      }),
    {
      retry: 0,
      onSuccess: (_, variables) => {
        variables?.onSuccess();
      },
      onError: (error: Error) => {
        showAlert({
          type: AlertType.ERROR,
          header: 'Failed to update asset hierarchy',
          message: `${error?.message || 'Request failed'}`,
        });
      },
    },
  );

  return mutation;
};
