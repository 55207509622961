import { FC } from 'react';

import { Locked, Unlocked } from '@assets/icons';

interface LockIconProps {
  isLocked: boolean;
  isInherited: boolean;
}

const LockIcon: FC<LockIconProps> = ({ isLocked, isInherited }) => {
  const iconClassName = isInherited ? 'text-dark-3' : 'text-primary';

  return isLocked ? (
    <Locked className={iconClassName} />
  ) : (
    <Unlocked className={iconClassName} />
  );
};

export default LockIcon;
