const RoutesPath = {
  ALL: '*',
  ROOT: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  SELECT_WORKSPACE: '/select-workspace',
  RESET_PASSWORD: '/reset-password',
  CREATE_PASSWORD: '/create-password',
  BRAND_LIBRARY: '/brand-library',
  MANAGE_TEAM: '/manage-team',
  NO_ACCESS: '/no-access',
  WORKSPACE: '/workspace',
  INTEGRATIONS: '/integrations',
};

export default RoutesPath;
