import { FC } from 'react';

import { Pause, Play } from '@assets/icons';

interface VideoPlayButtonProps {
  onClick: () => void;
  isPlaying: boolean;
}

const VideoPlayButton: FC<VideoPlayButtonProps> = ({ onClick, isPlaying }) => {
  return (
    <div
      className="h-10 w-10 bg-black rounded-full flex items-center justify-center play-button bg-opacity-50 cursor-pointer"
      onClick={onClick}
    >
      {isPlaying ? (
        <Pause className="text-white" />
      ) : (
        <Play className="text-white" />
      )}
    </div>
  );
};

export default VideoPlayButton;
